<div
  class="modal-backdrop"
  *ngIf="isModalOpen()"
  (click)="closeOnBackdropClick()"
></div>
<div
  *ngIf="isModalOpen()"
  class="modal-container py-1 flex justify-center items-safe-center w-screen h-screen overflow-auto hidden-scrollbar"
>
  <div
    class="modal bg-white block relative"
    [id]="id"
    [style.width.px]="width ? width : ''"
    [style.height.px]="height ? height : ''"
    [@child]
  >
    <div
      class="modal-header 2xl:p-6 p-5 flex items-center justify-between"
      [ngClass]="{
        'border-b-1 border-solid border-gray-200': showHeaderDivider
      }"
      *ngIf="header || closeButtonEnabled || headerIcon"
    >
      <div class="flex items-center gap-4">
        @if (headerIcon) {
        <span [innerHTML]="headerIcon | safe"></span>
        }
        <p class="font-bold leading-6">
          {{ header }}
        </p>
        <!-- Switch -->
        @if (headerSwitch && switchControl) {
        <label class="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            [formControl]="switchControl"
            class="sr-only peer"
            (change)="switchChange($event)"
          />
          <div
            class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:start-[3px] peer-checked:after:start-[8px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-[22px] after:h-[22px] after:transition-all peer-checked:bg-blue-600"
          ></div>
        </label>
        }
      </div>
      <span *ngIf="closeButtonEnabled" (click)="closeModal()">
        <svg
          class="w-2.5 h-2.5 stroke-gray-500 group-hover:stroke-gray-400 cursor-pointer"
          viewBox="0 0 14 14"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </span>
    </div>
    <ng-content class="modal-body" select="[slot=body]"></ng-content>
    <ng-content
      class="modal-footer"
      [ngClass]="{
        'border-t-1 border-solid border-gray-200': showFooterDivider
      }"
      select="[slot=footer]"
    ></ng-content>
  </div>
</div>
