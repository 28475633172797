import { Injectable } from '@angular/core';
import { Modal } from '../types/modal.type';
import {
  trigger,
  transition,
  style,
  animate,
  state,
  group,
  query,
  animateChild,
  AnimationTriggerMetadata,
} from '@angular/animations';
/**
 * Service for handling modals.
 * @class ModalService
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  /**
   * Array of modals.
   * @private
   * @type {Modal[]}
   */
  private modals: Modal[] = [];

  /**
   * Boolean flag indicating if a modal is shown.
   * @public
   * @type {boolean}
   */
  public isShown: boolean = false;

  /**
   * Animations for the modal.
   * @public
   * @type {AnimationTriggerMetadata[]}
   */
  static animations: AnimationTriggerMetadata[] = [
    trigger('parent', [
      state('in', style({ opacity: 1 })),
      transition(
        ':enter',
        group([
          query(
            '.modal-container',
            [style({ opacity: 0 }), animate('300ms ease-in-out')],
            { optional: true }
          ),
          query('@*', animateChild(), { optional: true }),
        ])
      ),
      transition(
        ':leave',
        group([
          query(
            '.modal-container',
            animate('200ms ease-in-out', style({ opacity: 0 })),
            { optional: true }
          ),
          query('@*', animateChild(), { optional: true }),
        ])
      ),
    ]),
    trigger('child', [
      state('in', style({ top: '50%', opacity: 1, visibility: 'visible' })),
      transition(':enter', [
        style({ top: '45%', opacity: '30%', visibility: 'visible' }),
        animate('300ms ease-in-out'),
      ]),
      transition(':leave', [
        animate(
          '200ms ease-in-out',
          style({ top: '45%', opacity: '30%', visibility: 'visible' })
        ),
      ]),
    ]),
  ];

  /**
   * Checks if a modal is open.
   * @public
   * @param {string} id - The id of the modal to check.
   * @returns {boolean} - Returns true if the modal is open, false otherwise.
   */
  isModalOpen(id: string): boolean {
    return !!this.modals.find((element) => element.id === id)?.visible;
  }

  /**
   * Toggles the visibility of a modal.
   * @public
   * @param {string} id - The id of the modal to toggle.
   */
  toggleModal(id: string) {
    let modal = null;
    if (!this.modals.some((element) => element.id === id)) {
      this.modals.push({ id, visible: true });
    } else {
      modal = this.modals.find((element) => element.id === id);
      if (modal) modal.visible = !modal.visible;
    }
  }

  /**
   * Removes a modal from the DOM.
   * @public
   * @param {string} id - The id of the modal to remove.
   */
  removeModal(id: string) {
    let element = document.getElementById(id);
    if (element) element.remove();
  }

  /**
   * Unregister a modal from the service.
   * @public
   * @param {string} id - The id of the modal to unregister.
   */
  unregister(id: string) {
    this.modals = this.modals.filter((element) => element.id !== id);
  }
}
